import Base from '../../_config/base';

class Accordion extends Base {
  constructor(el) {
    super(el);
    const allItems = [...el.querySelectorAll('.accordion__item')];

    // first item has open class

    // clickevent to open item, only one item open at one time
    allItems.forEach((item) => {
      item.addEventListener('click', () => {
        // open clicked item
        if (item.classList.contains('accordion__open')) {
          item.classList.remove('accordion__open');
        } else {
          allItems.forEach((itemI) => itemI.classList.remove('accordion__open'));
          item.classList.add('accordion__open');
        }
      });
    });
  }
}

Accordion.className = 'Accordion';
export default Accordion;
