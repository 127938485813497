import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import Base from '../../_config/base';

class Hero extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');
    const heroMovieVolume = el.querySelector('.hero__headermovie__volume');
    const heroMovie = el.querySelector('.hero__headermovie');

    if (slider !== null) {
      const sliderOpt = {
        modules: [EffectFade, Autoplay],
        effect: 'fade',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        mousewheel: false,
        keyboard: false,
      };

      // eslint-disable-next-line
      const swiper = new Swiper(slider, sliderOpt);
      swiper.autoplay.stop();

      setTimeout(() => {
        swiper.autoplay.start();
      }, 5000);
    }

    if (heroMovie && heroMovieVolume) {
      heroMovie.volume = 0;
      heroMovie.muted = true;
      heroMovieVolume.onclick = () => {
        if (heroMovie.muted) {
          heroMovie.volume = 1;
          heroMovie.muted = false;
          heroMovieVolume.classList.remove('hero__headermovie__volume--muted');
        } else {
          heroMovie.volume = 0;
          heroMovie.muted = true;
          heroMovieVolume.classList.add('hero__headermovie__volume--muted');
        }
      };

      // Set up IntersectionObserver to mute video when scrolled past
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              heroMovie.muted = true;
              heroMovieVolume.classList.add('hero__headermovie__volume--muted');
            }
          });
        },
        { threshold: 0 }, // Trigger when even a small part of the Hero is visible
      );

      observer.observe(el); // Observe the entire Hero section
    }
  }
}

Hero.className = 'Hero';
export default Hero;
