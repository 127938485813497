import Base from '../../_config/base';

class Sticky extends Base {
  constructor(el) {
    super(el);
    const sticky = el;

    let oldValue = 0;
    window.addEventListener('scroll', () => {
      // Get the new Value
      const newValue = window.pageYOffset;

      if (window.innerWidth < 1024) {
        // if sticky is 100% visible then add class
        if (newValue > oldValue && newValue > 177) {
          sticky.classList.add('sticky--stick');
        } else if (newValue < 177) {
          sticky.classList.remove('sticky--stick');
        }
      } else {
        // if sticky is 100% visible then add class
        // eslint-disable-next-line no-lonely-if
        if (newValue > oldValue && newValue > 793) {
          sticky.classList.add('sticky--stick');
        } else if (newValue < 793) {
          sticky.classList.remove('sticky--stick');
        }
      }

      // Update the old value
      oldValue = newValue;
    });
  }
}

Sticky.className = 'Sticky';
export default Sticky;
