import Base from '../../_config/base';

class Textvideo extends Base {
  constructor(el) {
    super(el);
    const video = el.querySelector('.textVideo__video video');

    if (video) {
      video.addEventListener('click', () => {
        // add atribute controls to video tag
        // set video fullscreen
        // eslint-disable-next-line max-len
        if (video.requestFullscreen) { video.requestFullscreen(); } else if (video.webkitRequestFullscreen) { video.webkitRequestFullscreen(); } else if (video.msRequestFullScreen) video.msRequestFullScreen();
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
        setTimeout(() => {
          video.setAttribute('controls', 'controls');
        }, 10);
      });
    }
  }
}

Textvideo.className = 'Textvideo';
export default Textvideo;
